import { Icon } from 'antd'
import React from 'react'

const PuzzleIcon = (props) => <Icon component={PuzzleSvg} {...props} />

// svg from Google Fonts
// https://fonts.google.com/icons?selected=Material+Symbols+Outlined:toys_and_games:FILL@0;wght@300;GRAD@-25;opsz@20&icon.query=toy&icon.size=16&icon.color=%23e8eaed&icon.set=Material+Symbols&icon.style=Outlined
const PuzzleSvg = () => (
  <svg width='1em' height='1em' viewBox='0 -1050 960 960' fill='currentColor'>
    <path d='M144-276q0-15 10.5-25.5T180-312q6 0 12 2t11 6q10 7 21.5 11.5T248-288q30 0 51-21t21-51q0-30-21-51t-51-21q-12 0-23.5 4T203-416q-5 4-11 6t-12 2q-15 0-25.5-10.5T144-444v-156q0-10 7-17t17-7h181q1 0 1 1t-1-1q-11-14-18-38.5t-7-44.5q0-66 45.5-111.5T480-864q65 0 110.5 45.5T636-707q0 21-7 44.5T611-624q-1 2-1 1t1-1h181q10 0 17 7t7 17v156q0 15-10.5 25.5T780-408q-6 0-12-2t-11-6q-10-8-21.5-12t-23.5-4q-30 0-51 21t-21 51q0 30 21 51t51 21q13 0 24.5-3.5T757-304q5-5 11-6.5t12-1.5q15 0 25.5 10.5T816-276v156q0 10-7 17t-17 7H168q-10 0-17-7t-7-17v-156Zm72 108h528v-51q-8 3-16 3h-16q-60 0-102-42t-42-102q0-60 42-102t102-42h16q8 0 16 3v-51H567q-20 0-33.5-13T520-597q0-8 1-15.5t6-13.5q11-17 24-39t13-42q0-35-24.5-60T480-792q-35 0-59.5 25T396-707q0 15 10.5 37.5T433-626q5 6 7 13.5t2 15.5q0 20-14.5 32.5T393-552H216v51q8-2 16-2.5t16-.5q60 0 102 42t42 102q0 60-42 102t-102 42q-8 0-16-.5t-16-2.5v51Zm264-312Z' />
  </svg>
)

export default PuzzleIcon
