import PuzzleIcon from 'components/PuzzleIcon/PuzzleIcon'
import { errorToMessage } from 'shared/utils/errors'
import { activityTypes } from 'shared/utils/data'
import palette from 'shared/theme/vars/palette'
import deepEqual from '@f/deep-equal'
import mapValues from '@f/map-values'
import avatars from 'assets/avatars'
import setProp from '@f/set-prop'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import get from 'lodash/get'

export function isEmail (str) {
  return str && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(str)
}

export const getAvatarByValue = password =>
  (avatars.find(a => a.value === password) || {}).src

export const setArrayImmutable = (arr, i, value) =>
  Object.assign([...arr], { [i]: value })

export function stopEvent (action = () => {}) {
  return e => {
    e.stopPropagation()
    e.preventDefault()
    action(e)
  }
}

export const gradeToColor = grade => {
  if (grade === null) return
  if (grade >= 80) {
    return palette['@green']
  } else if (grade >= 70) {
    return palette['@yellow']
  } else {
    return palette['@red']
  }
}

export const filterProgress = progress =>
  progress.filter(
    ({ type }) => activityTypes.find(({ value }) => value === type).progress
  )

export const getProgress = (progress = {}, activities = []) => {
  const { activities: myActivities = {} } = progress
  const myProgress = filterProgress(
    mapValues(
      activity => ({ ...activity, ...(myActivities[activity.id] || {}) }),
      activities || []
    )
  )

  const numActivities = myProgress.length
  const numCompleted = myProgress.reduce(
    (acc, { completed }) => acc + (completed ? 1 : 0),
    0
  )

  return {
    completed: numCompleted >= numActivities,
    numCompleted,
    numActivities
  }
}

export const getPercent = (progress = {}, activities) => {
  const { numCompleted, numActivities } = getProgress(progress, activities)
  const hasProgress = !!numActivities
  const percent = hasProgress
    ? (numCompleted / numActivities) * 100
    : progress.isStarted
      ? 100
      : 0
  return percent
}

export const progressPercent = (progress = []) =>
  Math.round(
    progress.reduce((acc, p) => acc + (p.progress || 0), 0) / progress.length
  ) || 0

export function stopProp (action = () => {}) {
  return e => {
    e.stopPropagation()
    action(e)
  }
}

export function validate (validator, cast, overWrites) {
  return (values, props, next) => {
    const { valid, errors } = validator(cast(values, props), { greedy: true })
    if (valid) return

    return getValidationErrors(
      {
        errorDetails: errors
      },
      overWrites
    )
  }
}

export function ensureHttp (url) {
  if (!url) return ''
  return url.match(/^[a-zA-Z]+:\/\//) ? url : 'http://' + url
}

/**
 * @function padArray
 * @param {number} length
 * @param {array} arr
 * @returns an array that has at least a minimum length.
 */
export function padArray (length, arr = []) {
  return arr.concat(
    Array.apply(null, Array(Math.max(0, length - arr.length))).map(() => {})
  )
}

export function getFormDefaults (validator, cast, overWrites) {
  return {
    validateOnChange: true,
    validateOnBlur: false,
    validate: validate(validator, cast, overWrites)
  }
}

export function havePropsChanged (keys, obj1, obj2) {
  return [].concat(keys).some(key => !deepEqual(obj1[key], obj2[key]))
}

/**
 * @function getValidationError
 * @param {object} e The error object to transform
 * @returns object with {errorField: message}
 */
export function getValidationErrors (e, overWrites = {}) {
  return (e.errorDetails || []).reduce(
    (acc, { field, message }) =>
      setProp(field, acc, errorToMessage(message, overWrites[field])),
    {}
  )
}

/**
 * @function numCompare
 * @param {number} a
 * @param {number} b
 * @returns the difference between two numbers or
 * +/- Infinity for non number values
 */
export function numCompare (a, b) {
  const first = isNaN(a) || a === undefined || a === null ? -Infinity : a
  const second = isNaN(b) || b === undefined || b === null ? -Infinity : b
  const diff = first - second

  return isNaN(diff) ? -Infinity : diff
}

function padStart (string = '', length = 0, chars = ' ') {
  if (typeof string !== 'string' && typeof string !== 'number') {
    throw new Error('First argument must be a string or a number')
  }
  if (typeof chars !== 'string' && typeof chars !== 'number') {
    throw new Error('Third argument must be a string or a number')
  }
  if (typeof length !== 'number') {
    throw new Error('Second argument must be a number')
  }

  const strLength = String(string).length
  const charsLength = String(chars).length
  const prefix = []
  for (var i = 0; i < length - strLength; i++) {
    prefix.push(chars[i % charsLength])
  }
  return prefix.join('') + string
}

export const memoize = fn => {
  const cache = {}
  return (...args) => {
    const stringArgs = JSON.stringify(args)
    cache[stringArgs] = cache[stringArgs] || fn(...args)
    return cache[stringArgs]
  }
}

export const getGridSize = size => {
  const numCols = Math.min(Math.ceil(Math.sqrt(size)), 4)
  const span = Math.min(Math.max(2, Math.ceil(24 / numCols), 6))
  return {
    numCols,
    span
  }
}

export const isNextGen = lessonOrModule => lessonOrModule.type === 'NEXT_GEN'

export const getSlidesLink = lessonData => {
  // note lesson data could be NEXT_GEN and still return slides if lesson data
  // does not include class specific lesson data
  if (isNextGen(lessonData) && lessonData.teacherUrl) {
    return lessonData.teacherUrl
  } else {
    return lessonData.slides
  }
}

export const getNextGenClassLessonData = (classData, moduleId, lessonId) => {
  const classLessonData = get(
    classData,
    `assignedModules.${moduleId}.assignedLessons.${lessonId}`,
    {}
  )
  return isNextGen(classLessonData)
    ? pick(classLessonData, ['studentUrl', 'teacherUrl'])
    : {}
}

/**
 * @function getClassLessonData
 * @param {Object|undefined} lessonData
 * @param {Object} classData
 * @returns {Object|undefined} returns undefined if lessonData is undefined or
 * lesson data with NextGen class lesson data if lesson is type NEXT_GEN
 */
export const getClassLessonData = (lessonData, classData) => {
  if (!lessonData) {
    return
  }

  return merge(
    lessonData,
    getNextGenClassLessonData(classData, lessonData.moduleId, lessonData.id)
  )
}

export const getNextGenCustomPreviewData = lessonData => {
  if (!isNextGen(lessonData)) {
    return
  }

  if (lessonData.integration.startsWith('rewriters')) {
    return {
      iconComponent: PuzzleIcon,
      text: 'Try Puzzles'
    }
  }
}

export const getLessonPlanDisplayText = lessonData =>
  isNextGen(lessonData) ? 'Lesson Guide' : 'Lesson Plan'

export { default as getPreviewLink } from './getPreviewLink'
